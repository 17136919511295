import deepEqual from "fast-deep-equal";
import {
  EUserType,
  IProviderConfig,
  IUserInfo,
} from "../account/account-models";
import { API_ROOT } from "../globals";
import { IDictionary } from "../models";
import {
  addOnlineUser,
  addOnlineUsers,
  removeOnlineUser,
  setUnreadMessages,
  setUnreadMessagesFavorites,
} from "../pubnub/pubnub-action-creators";
import { PubnubActions } from "../pubnub/pubnub-actions";
import { isOnline } from "../utils/time";
import { defined } from "../utils/variable-evaluation";
import { IChatInfo } from "./chats-models";

export enum EAltChatType {
  Fans = "fans",
  Following = "affiliate",
}

export enum EChatFilterType {
  Search = "search",
  Category = "category",
}

export enum EChatFilterCategory {
  All = "all",
  Online = "online",
  Favorites = "favorites",
  Fans = "fans",
  Followers = "followers",
  Subscribers = "subscribers",
  Unreplied = "unreplied",
  Vip = "vip",
  Sent = "sent",
  Recent = "recent",
}

export const DEFAULT_CHAT_FILTERS_STATE = {
  [EChatFilterType.Search]: {
    type: EChatFilterType.Search,
    value: null,
  },
  [EChatFilterType.Category]: {
    type: EChatFilterType.Category,
    value: EChatFilterCategory.All,
  },
};

export const TO_API_KEY = {
  [EChatFilterType.Search]: "search",
  [EChatFilterType.Category]: "type",
};

export const TO_API_VALUE = {
  [EChatFilterType.Category]: {
    [EChatFilterCategory.Fans]: "fans2",
  },
};

export interface IChatFilter {
  type?: EChatFilterType;
  value?: any;
}

export function isDefaultFiltersSet(filtersSet: IDictionary<IChatFilter>) {
  return deepEqual(filtersSet, DEFAULT_CHAT_FILTERS_STATE);
}

export function isMatchingFiltersSet(
  filtersSet: IDictionary<IChatFilter>,
  otherFiltersSet: IDictionary<IChatFilter>
) {
  return deepEqual(filtersSet, otherFiltersSet);
}

export interface IGetChatsConfig {
  user: IUserInfo;
  providerConfig: IProviderConfig;
  isInitialRequest: boolean;
  isSneakyReload?: boolean;
  filtersSet?: IDictionary<IChatFilter>;
  altChatType?: EAltChatType;
}

// export function isChatSelected(props: IChats.Props, selectedChat: IChatInfo) {
//   return (
//     defined(props.match.params.otherUserId) &&
//     defined(props.user) &&
//     exists(selectedChat) &&
//     isRoute('chats', props.location)
//   );
// }

// export function isChatSelectedFromList(props: IChat.Props, nextProps: IChat.Props) {
//   return (
//     exists(nextProps.match.params.otherUserId) &&
//     nextProps.match.params.otherUserId !== props.match.params.otherUserId
//   );
// }

// export function isChatSelectedFromDiscover(props: IChat.Props, nextProps: IChat.Props) {
//   return (
//     exists(nextProps.match.params.otherUserId) &&
//     isRoute('discover', props.location) &&
//     isRoute('chats', nextProps.location)
//   );
// }

// export function isPathChange(props: IChats.Props, nextProps: IChats.Props) {
//   return props.location.pathname !== nextProps.location.pathname;
// }

// export function isChatsChange(props: IChats.Props, nextProps: IChats.Props) {
//   return props.getChats.status !== nextProps.getChats.status;
// }

// export function isDefaultFirstChat(props: IChats.Props) {
//   return (
//     isFetched(props.getChats) &&
//     queryStrToDictionary(props.location.search)['lock'] !== 'true' &&
//     defined(props.chats[0])
//   );
// }

export function getChatsUrl(config: IGetChatsConfig) {
  const { filtersSet, altChatType } = config;

  const volumeQueries = "?v=2&limit=20&offset=0"; // v=2 adds prospects to response
  let url = `${API_ROOT}/v1/chats${volumeQueries}`;

  if (filtersSet) {
    Object.keys(filtersSet).forEach((filterKey) => {
      const filter = filtersSet[filterKey];
      const apiKey = (TO_API_KEY as any)[filterKey];
      let apiValue = filter.value;
      if (
        defined((TO_API_VALUE as any)[filterKey]) &&
        defined((TO_API_VALUE as any)[filterKey][filter.value])
      ) {
        apiValue = (TO_API_VALUE as any)[filterKey][filter.value];
      }
      if (defined(apiValue) && apiValue !== "") {
        url += `&${apiKey}=${apiValue}`;
      }
    });
  }
  if (defined(altChatType)) {
    url += `&type=${altChatType}`;
  }
  return url;
}

// PRESENCE

export function setPresenceOnArray(
  chats: IChatInfo[],
  pubnubActions: PubnubActions
) {
  const onlineIds = chats
    .filter((chat: IChatInfo) => isOnline(chat.other_user.last_online))
    .map((chat: IChatInfo) => chat.other_user._id);
  addOnlineUsers(onlineIds, pubnubActions);
}

export function setPresence(chat: IChatInfo, pubnubActions: PubnubActions) {
  if (isOnline(chat.other_user.last_online)) {
    addOnlineUser(chat.other_user._id, pubnubActions);
  } else {
    removeOnlineUser(
      chat.other_user._id,
      chat.other_user.last_online,
      pubnubActions
    );
  }
}

// USER RELATIONSHIP TYPES

export const isUserToUser = (
  userType: EUserType,
  otherUserType: EUserType
): boolean => userType === EUserType.User && otherUserType === EUserType.User;
export const isFeaturedToFeatured = (
  userType: EUserType,
  otherUserType: EUserType
): boolean =>
  userType === EUserType.Featured && otherUserType === EUserType.Featured;
export const isUserToFeatured = (
  userType: EUserType,
  otherUserType: EUserType
): boolean =>
  userType === EUserType.User && otherUserType === EUserType.Featured;
export const isFeaturedToUser = (
  userType: EUserType,
  otherUserType: EUserType
): boolean =>
  userType === EUserType.Featured && otherUserType === EUserType.User;
export const isFeaturedToFan = (
  user: IUserInfo,
  otherUserAffiliate: string
): boolean =>
  user.type === EUserType.Featured && user._id === otherUserAffiliate;
export const isUserStaff = (user: IUserInfo, otherUser: IUserInfo): boolean =>
  (isFeaturedToFeatured(user.type, otherUser.type) &&
    (user.is_staff || user.is_staff_mo)) ||
  (isFeaturedToUser(user.type, otherUser.type) && user.is_staff);
export const isUserSecretStaff = (
  user: IUserInfo,
  otherUser: IUserInfo
): boolean => isFeaturedToUser(user.type, otherUser.type) && !!user.is_staff_mo;
export const isOtherUserStaff = (
  user: IUserInfo,
  otherUser: IUserInfo
): boolean =>
  (isFeaturedToFeatured(user.type, otherUser.type) &&
    (otherUser.is_staff || otherUser.is_staff_mo)) ||
  (isUserToFeatured(user.type, otherUser.type) && otherUser.is_staff);

export const isSelfUser = (userId: string, otherUserId: string) =>
  userId === otherUserId;

// FREE TRIAL CHAT LOGIC

export function isFreeChat(
  userType: EUserType,
  userIsStaff: boolean,
  otherUserType: EUserType,
  otherUserCreditRate: number
) {
  return (
    isUserToUser(userType, otherUserType) || //user to user chat
    isFeaturedToFeatured(userType, otherUserType) || //fm to fm chat
    isFeaturedToUser(userType, otherUserType) ||
    userIsStaff || //chat with staff
    (otherUserType === EUserType.Featured && otherUserCreditRate === 0)
  ); //fm disabled billing
}

export function isFreeTrialChat(
  user: IUserInfo,
  otherUser: IUserInfo,
  selectedChat: IChatInfo
) {
  if (otherUser.type !== EUserType.Featured || user.type !== EUserType.User) {
    return false;
  } else if (defined(selectedChat)) {
    return !selectedChat.intro_ended && selectedChat.is_intro;
  } else {
    return otherUser.intro_enabled && user.can_intro;
  }
}

export function transformChatResponse(
  res: any,
  config: IGetChatsConfig,
  pubnubActions: PubnubActions
) {
  if (defined(res.unread_messages)) {
    setUnreadMessages(res.unread_messages, pubnubActions);
  }
  if (defined(res.unread_messages_favorites)) {
    setUnreadMessagesFavorites(res.unread_messages_favorites, pubnubActions);
  }
  if (defined(res.data)) {
    setPresenceOnArray(res.data, pubnubActions);
  } else {
    res.data = [];
  }
  return res;
}

export function getChatsFilterCategoryLabel(category: EChatFilterCategory) {
  switch (category) {
    case EChatFilterCategory.All:
      return "Inbox";
    case EChatFilterCategory.Online:
      return "Online";
    case EChatFilterCategory.Fans:
      return "Fans";
    case EChatFilterCategory.Favorites:
      return "Favorites";
    case EChatFilterCategory.Recent:
      return "Recent";
    case EChatFilterCategory.Followers:
      return "Followers";
    case EChatFilterCategory.Subscribers:
      return "Subscribers";
    case EChatFilterCategory.Vip:
      return "VIP";
    case EChatFilterCategory.Unreplied:
      return "Unreplied";
    case EChatFilterCategory.Sent:
      return "Sent";
    default:
      return "";
  }
}

export function getChatsFilterCategoryAmount(
  total: number,
  isEditing?: boolean,
  chatsSelection?: string[],
  isSelectAll?: boolean
) {
  if (!isEditing) {
    return total;
  }

  let amount;
  if (isSelectAll) {
    amount = total - chatsSelection!.length;
  } else {
    amount = chatsSelection!.length;
  }

  if (amount < 0 || isNaN(amount)) {
    return 0;
  }
  return amount;
}
